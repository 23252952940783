import './App.css';
import React from 'react';
import {ethers} from "ethers";
import nftcContractAbi from "./NFTC-abi.json";

let nftcContractAddr = "0xD2da8B93475fC3d50a82Ac208DAfD8bc543C9Be1";

// Reload page if network or wallet is changed
if (window.ethereum) {
    window.ethereum.on('networkChanged', function (networkId) {
        window.location.reload();
    });
}

class MyLoginButton  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: props.disabled,
            label: props.label,
            afterLabel: props.afterLabel
        };

        this.handleClick = this.handleClick.bind(this);
    }
    
    async handleClick() {
        const oldLabel = this.state.label;
        
        this.setState({
            label: "⌛",
            disabled: "disabled"
        });
        
        await this.props.clickHandler();

        this.setState({
            label: oldLabel,
            disabled: ""
        });
    }
        
    render () {
        return (
            <div className="MyButton">
                <button onClick={this.handleClick} disabled={this.state.disabled}>{this.state.label}</button>{this.state.afterLabel}
            </div>
        );
    }
}

class MyClaimButton  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: props.disabled,
            label: props.label,
            afterLabel: props.afterLabel
        };

        this.handleClick = this.handleClick.bind(this);
    }
    
    async handleClick() {
        const oldLabel = this.state.label;
        
        this.setState({
            label: "⌛",
            disabled: "disabled"
        });
        
        await this.props.clickHandler();

        this.setState({
            label: oldLabel,
            disabled: ""
        });
    }
    
    render () {
        return (
            <div className="MyButton">
                <button onClick={this.handleClick} disabled={this.state.disabled}>{this.state.label}</button>{this.state.afterLabel}
            </div>
        );
    }
}

class MyMintButton  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: props.disabled,
            label: props.label,
            afterLabel: props.afterLabel
        };

        this.handleClick = this.handleClick.bind(this);
    }
    
    async handleClick() {
        const oldLabel = this.state.label;
        
        this.setState({
            label: "⌛",
            disabled: "disabled"
        });
        
        await this.props.clickHandler();

        this.setState({
            label: oldLabel,
            disabled: ""
        });
    }
    
    render () {
        return (
            <div className="MyButton">
                <button onClick={this.handleClick} disabled={this.state.disabled}>{this.state.label}</button>{this.state.afterLabel}
            </div>
        );
    }
}

class UserPane extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            hasClaimed: false,
            address: null,
            balance: null,
            isMumbaiTestnet: true,
            userMessage: ""
        };

        this.tryLogin = this.tryLogin.bind(this);
        this.tryClaim = this.tryClaim.bind(this);
        this.tryMint = this.tryMint.bind(this);
    }
    
    // Allow login
    async tryLogin() {
        if (!this.state.isLoggedIn) {
            const ethProvider = new ethers.providers.Web3Provider(window.ethereum);
            if (!ethProvider) {
                console.error("Please install MetaMask !");
                return;
            }

            const currNetwork = await ethProvider.getNetwork();
            const isMumbaiTestnet = (currNetwork.chainId.toString() === "80001");

            if (!isMumbaiTestnet) {
                console.error('Please switch to Mumbai Network');
                this.setState({
                    isMumbaiTestnet: isMumbaiTestnet,
                });
                return;
            }


            try {
                await ethProvider.send('eth_requestAccounts', []);
                const signer = await ethProvider.getSigner();
        
                const signerAddress = await signer.getAddress();
                const signerBalance = await signer.getBalance();
        
                const NFTC = new ethers.Contract(nftcContractAddr, nftcContractAbi, signer);
                const userOGBalance = await NFTC.balanceOf(signerAddress, 1);
                        
                if (userOGBalance > 0) {
                    this.setState({
                        isLoggedIn: true,
                        hasClaimed: true,
                        userMessage: "You already own an OG NFT",
                        address: signerAddress,
                        balance: signerBalance
                    });            
                } else {        
                    this.setState({
                        isLoggedIn: true,
                        address: signerAddress,
                        balance: signerBalance
                    });
                }
                
                window.ethereum.on('accountsChanged', function (accounts) {
                    window.location.reload();
                });
            } catch (error) {
                console.log(error);
            }
        }
    }

    // Permet de Claim le NFT OG
    async tryClaim() {
        const ethProvider = new ethers.providers.Web3Provider(window.ethereum);
        if (!ethProvider) {
            this.setState({userMessage: "Please install MetaMask !"});
            return;
        }

        const signer = await ethProvider.getSigner();

        const NFTC = new ethers.Contract(nftcContractAddr, nftcContractAbi, signer);
        try {
            await NFTC.mint_og();
            this.setState({
                userMessage: "OK ! Check your wallet ;)",
                hasClaimed: true
            });
        } catch (error) {
            this.setState({userMessage: "Claim annulé"});
        }      
    }

    // Try to mint NFT #2
    async tryMint() {
        const ethProvider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = await ethProvider.getSigner();

        const NFTC = new ethers.Contract(nftcContractAddr, nftcContractAbi, signer);
        const mintValue = await NFTC.getPriceById(2);
        try {
            await NFTC.mintId(2, {value: mintValue.toString()});
            this.setState({
                userMessage: "OK ! Check your wallet ;)"
            });
        } catch (error) {
            this.setState({userMessage: "Mint annulé"});
            console.log(error);
        }      
    }

    render () {
        if (!window.ethereum) {
            console.error("Please install MetaMask !");
            return <div className="header-message">Please install MetaMask !</div>;
        }
        if (!this.state.isMumbaiTestnet) {
            return <div className="header-message">Please switch to Mumbai Network</div>;
        }
        if (!this.state.isLoggedIn) {
            return <MyLoginButton clickHandler={this.tryLogin} isDisabled={false} label="Connect Metamask" afterLabel="" />;
        } else {
            if (!this.state.hasClaimed) {
                return <div>
                <MyClaimButton clickHandler={this.tryClaim}  isDisabled={false} label="Claim OG NFT" afterLabel="" />
                <MyMintButton clickHandler={this.tryMint}  isDisabled={false} label="Mint NFT#2 (0.05ETH)" afterLabel="" />
                <p className="signerAddress">Addr : {this.state.address}</p>
                <p className="userMessage">{this.state.userMessage}</p>
                </div>;
            } else {
                return <div>
                    <MyMintButton clickHandler={this.tryMint}  isDisabled={false} label="Mint NFT #2  (0.05ETH)" afterLabel="" />
                    <p className="signerAddress">Addr : {this.state.address}</p>
                    <p className="userMessage">{this.state.userMessage}</p>
                </div>;
            }
        }
    }
}


function App() { 
    return (
    <div className="App">
      <header className="App-header">
        <img decoding="async" src="https://nowfreetocreate.com/wp-content/uploads/elementor/thumbs/NFTC-pxdsf2rqyk9lr90ywbx5rxcfpaj08xeke631hvcd1c.png" title="NFTC" alt="NFTC" />
        <a
          className="App-link"
          href="https://nowfreetocreate.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </a>
        <UserPane />
      </header> 
    </div>
    );
}

export default App;
